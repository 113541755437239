<template>
  <div class="policy-read">
    <div v-html="pageHtml"></div>
  </div>
</template>

<script>
  import { GetTemplateContentByTemplateId } from '@/api/knowledgeBase/template'
export default {
  data() {
    return {
      pageH: `${document.body.clientHeight}px`,
      accessToken: localStorage.getItem('accessToken'),
      id: this.$route.query.id,
      type: Number(this.$route.query.t),
      iframeSrc: '',
      pageHtml: '',
      appName: '',
    }
  },
  created() {
    document.body.className = 'bg-white'
    document.title = ' 模板预览'
    if (this.id) {
      this.getTemplateContentByTemplateId()
    } else {
      this.pageHtml = localStorage.getItem('htmlStr')
    }
  },
  methods: {
    getTemplateContentByTemplateId() {
      GetTemplateContentByTemplateId({ code: this.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const contents = resData.data
          contents.forEach(c => {
            this.pageHtml += c.content
          })
        }
      })
    },

  },
}
</script>

<style lang="scss">
  .bg-white{
    background: #ffffff !important;
  }
.policy-read{
  width: 1200px;
  margin: 20px auto 20px auto;
  table{
    width: 100%;
    table-layout: fixed;
    box-sizing: border-box;
    border-spacing: 0;
    page-break-inside: avoid!important;
    border-collapse: collapse;
  }
  td,th{
    box-sizing: border-box;
    border: 1px solid #666666;
    page-break-inside: avoid!important;
  }
}
</style>
